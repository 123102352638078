import React, { useState } from "react"
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom"

import EnhancedPublicRoute from "@/components/EnhancedPublicRoute"
import Layout from "@/components/Layout"
import ProtectedRoute from "@/components/ProtectedRoute"
import ROUTES from "@/constants/routes"
import AccessControlKeys from "@/containers/AccessControlKeys"
import CreateApplicationScreen from "@/containers/ApplicationCreate"
import ApplicationDetailsScreen from "@/containers/ApplicationDetails"
import ApplicationsScreen from "@/containers/Applications"
import CodeExchange from "@/containers/CodeExchange"
import DeveloperResources from "@/containers/DeveloperResources"
import Error from "@/containers/Error"
import Groups from "@/containers/Groups"
import { GroupsCreate } from "@/containers/GroupsCreate"
import GroupsEdit from "@/containers/GroupsEdit"
import Login from "@/containers/Login"
import MySources from "@/containers/MySources"
import SourcesAvailable from "@/containers/SourcesAvailable"
import SourcesCreate from "@/containers/SourcesCreate"
import SourcesEdit from "@/containers/SourcesEdit"
import StartLogout from "@/containers/StartLogout"
import ViewAccessControlKeys from "@/containers/ViewAccessControlKeys"
import { AuthProvider } from "@/context/AuthContext"
import LayoutOptsContext from "@/context/LayoutOptsContext"
import { getPublicUrl } from "@/utilities"

import { config } from "./settings"

import "react-toastify/dist/ReactToastify.css"

function App() {
  const [layoutOpts, setLayoutOpts] = useState({
    nav: true,
    footer: true,
  })

  const APPLICATION_ROUTES = []
  if (!config.disableApps) {
    APPLICATION_ROUTES.push(
      ...[
        <ProtectedRoute
          path={ROUTES.APPLICATIONS}
          exact
          component={ApplicationsScreen}
          key={"apps"}
        />,
        <ProtectedRoute
          path={ROUTES.APPLICATION_CREATE}
          component={CreateApplicationScreen}
          key={"create-app"}
        />,
        <ProtectedRoute
          path={ROUTES.APPLICATION_EDIT}
          component={ApplicationDetailsScreen}
          key={"get-app"}
        />,
      ]
    )
  }

  return (
    <LayoutOptsContext.Provider value={{ layoutOpts, setLayoutOpts }}>
      <AuthProvider>
        <Router basename={getPublicUrl()}>
          <Layout includeNavBar={layoutOpts.nav} includeFooter={layoutOpts.footer}>
            <Switch>
              <Route exact path="/">
                <Redirect to={ROUTES.RESOURCES} />
              </Route>
              <Route exact path={ROUTES.LOGIN} component={Login} />
              <Route path={ROUTES.LOGIN_RETURN} component={CodeExchange} />
              <ProtectedRoute exact path={ROUTES.SOURCE_EDIT} component={SourcesEdit} />
              <ProtectedRoute path={ROUTES.SOURCE_CREATE} component={SourcesCreate} />

              <EnhancedPublicRoute
                path={ROUTES.SOURCES_AVAILABLE}
                component={SourcesAvailable}
              />
              <EnhancedPublicRoute
                path={ROUTES.RESOURCES}
                component={DeveloperResources}
              />

              <ProtectedRoute exact path={ROUTES.MY_SOURCES} component={MySources} />
              <ProtectedRoute path={ROUTES.GROUP_CREATE} component={GroupsCreate} />
              <ProtectedRoute exact path={ROUTES.GROUP_EDIT} component={GroupsEdit} />
              <ProtectedRoute exact path={ROUTES.GROUP_LIST} component={Groups} />
              <ProtectedRoute
                exact
                path={ROUTES.ACCESS_CONTROL_KEYS}
                component={AccessControlKeys}
              />
              <ProtectedRoute
                path={ROUTES.ACCESS_CONTROL_KEYS_VIEW}
                component={ViewAccessControlKeys}
              />
              <Route path={ROUTES.START_LOGOUT} component={StartLogout} />

              {APPLICATION_ROUTES}

              <Redirect
                from={ROUTES.LOGOUT}
                to={{
                  pathname: ROUTES.LOGIN,
                  state: { status: "You have been successfully logged out!" },
                }}
              />
              <Route component={Error} />
            </Switch>
          </Layout>
        </Router>
      </AuthProvider>
    </LayoutOptsContext.Provider>
  )
}

export default App
