import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import {
  Button,
  IconButton,
  IconLink,
  InfoCard,
  LinkButton,
  styled,
} from "@ioxio-priv/dataspace-ui"
import { Typography, useMediaQuery, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"

import Text from "@/components/Text"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes"
import GroupsWrapper from "@/containers/GroupsWrapper"
import InitialLoading from "@/containers/InitialLoading"
import { Icons } from "@/dsIcon"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import useMyGroups from "@/hooks/useMyGroups"
import useToast from "@/hooks/useToast"
import InvitesAPI from "@/services/invitesAPI"

const addGroupButtonSx = {
  mb: "1.5rem",
}

const invitesHeadingTextSx = {
  fontWeight: "500",
  margin: "1.5rem 0",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}

const declineButtonSx = {
  marginRight: "1rem",
}

function GroupItem({ groupName }) {
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <GroupItemGrid container data-testid="group-item">
      <Grid item>
        <Typography data-testid={`groupName-${groupName}`} variant="h3">
          {groupName}
        </Typography>
      </Grid>
      <Grid item>
        {mobileScreen ? (
          <IconLink
            icon={Icons.eye}
            baseProps={{ "data-testid": `mobile-edit-${groupName}` }}
            iconVariant="outlined"
            href={`${ROUTES.GROUP_LIST}/${groupName}`}
          />
        ) : (
          <LinkButton
            baseProps={{ "data-testid": `edit-${groupName}` }}
            href={`${ROUTES.GROUP_LIST}/${groupName}`}
            icon={Icons.eye}
            iconVariant="outlined"
          >
            View
          </LinkButton>
        )}
      </Grid>
    </GroupItemGrid>
  )
}

export default function Groups({ location }) {
  const { GROUP_CREATE } = ROUTES
  const [myGroupInvites, setMyGroupInvites] = useState([])
  const [loadingInvites, setLoadingInvites] = useState(true)

  // render navbar and footer
  useLayoutOpts(true, true)

  // fetch my groups
  const { myGroups, loading, setMyGroups } = useMyGroups()

  // fetch group invitations
  useEffect(() => {
    ;(async () => {
      const { ok, data, error } = await InvitesAPI.getMyGroupInvites()
      if (ok) {
        setMyGroupInvites(data.invites)
      } else {
        toast.error(error)
      }
      setLoadingInvites(false)
    })()
  }, [])

  // Initialize toasts and render any in the state
  useToast(location.state)

  // handle invite response
  async function handleInviteResponse(inviteId, response) {
    const { ok, data, error } = await InvitesAPI.respondToInvite(inviteId, response)
    if (ok) {
      toast.success(
        `Invite ${response === "accept" ? "accepted" : "declined"} successfully`
      )
      setMyGroupInvites(data.invites)
      setMyGroups(data.groups)
    } else {
      toast.error(error)
    }
  }

  const InviteCount = styled("span")`
    border-radius: 2rem;
    background: ${(p) =>
      myGroupInvites.length > 0
        ? p.theme.palette.success.main
        : p.theme.palette.primary.main};
    color: #fff;
    width: 1.4rem;
    height: 1.4rem;
    text-align: center;
  `

  return (
    <GroupsWrapper
      belowHeader={
        <div>
          <LinkButton
            variant={"iconText"}
            icon={Icons.add}
            href={GROUP_CREATE}
            baseProps={{ sx: addGroupButtonSx, "data-testid": "add-group" }}
            color={"success"}
          >
            {labels.group.buttons.add}
          </LinkButton>
        </div>
      }
      meta={labels.meta.myGroups}
      title={"My groups"}
    >
      <hr style={{ borderTop: 0 }} />
      {!loading && !loadingInvites ? (
        <>
          <div>
            <Text csx={invitesHeadingTextSx}>
              Pending group invitiations{" "}
              <InviteCount data-testid={"group-invites-count"}>
                {myGroupInvites.length}
              </InviteCount>
            </Text>
            {myGroupInvites.length > 0 &&
              myGroupInvites.map((invite) => (
                <div key={invite.inviteId} data-testid={"group-invites"}>
                  <Text>
                    You have been invited to join the group <b>{invite.group}</b> as
                    {invite.role === "member" ? " a" : " an"} <b>{invite.role}</b> by{" "}
                    {invite.inviterEmail}.
                  </Text>
                  <ButtonContainer>
                    <Button
                      baseProps={{ "data-testid": "decline", sx: declineButtonSx }}
                      icon={Icons.cancel}
                      variant={"outlined"}
                      onClick={() => handleInviteResponse(invite.inviteId, "decline")}
                      color={"secondary"}
                    >
                      Decline
                    </Button>
                    <Button
                      baseProps={{ "data-testid": "accept" }}
                      icon={Icons.success}
                      onClick={() => handleInviteResponse(invite.inviteId, "accept")}
                      color={"success"}
                    >
                      Accept
                    </Button>
                  </ButtonContainer>
                  <IconButtonContainer>
                    <IconButton
                      baseProps={{ "data-testid": "decline", sx: declineButtonSx }}
                      icon={Icons.cancel}
                      variant={"outlined"}
                      onClick={() => handleInviteResponse(invite.inviteId, "decline")}
                      color={"primary"}
                    >
                      Decline
                    </IconButton>
                    <IconButton
                      baseProps={{ "data-testid": "accept" }}
                      icon={Icons.success}
                      onClick={() => handleInviteResponse(invite.inviteId, "accept")}
                      color={"success"}
                    >
                      Accept
                    </IconButton>
                  </IconButtonContainer>

                  <HorizontalLine />
                </div>
              ))}
          </div>
          {!myGroupInvites.length && <hr style={{ borderTop: 0 }} />}
          <Grid container mt={"1.5rem"}>
            {myGroups.map((item) => (
              <GroupItem key={item.group} groupName={item.group} />
            ))}
            {myGroups.length === 0 && <InfoCard>You have no groups</InfoCard>}
          </Grid>
        </>
      ) : (
        <InitialLoading />
      )}
    </GroupsWrapper>
  )
}

const GroupItemGrid = styled(Grid)`
  border: 1px solid ${(p) => p.theme.palette.neutral.main};
  border-radius: 0.3125rem;
  padding: 1rem;
  margin: 0 0 1.5rem;
  justify-content: space-between;
  align-items: center;
  background: ${(p) => p.theme.palette.secondary.light};
`

const ButtonContainer = styled(Box)`
  margin: 1rem 0 1.5rem;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`

const IconButtonContainer = styled(Box)`
  margin: 1rem 0 1.5rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`

const HorizontalLine = styled("hr")`
  margin-bottom: 1.5rem;
  border-top: none;
`
